<template lang="pug">
  div.my-5
    .container
      h1 
      form(@submit.prevent="exec")
        .form-group
          label メソッド
          select#method.form-control(name="methodName" v-model="method" required)
            option(value="GET") GET
            option(value="POST") POST
            option(value="PATCH") PATCH
            option(value="DELETE") DELETE
        .form-group
          label URL
          input.form-control(type="text" name="URL" v-model="url")
        .form-group
          label データ
          input.form-control(type="text" name="date" v-model="data")
        .form-group
          input.btn.btn-primary(type="submit" value="実行")
        .form-group
          label 結果:
          textarea.form-control(type="text" name="result" rows="5" v-model="result")
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      method: this.method,
      url: this.url,
      data: '{"id":3, "name":"yotsui"}',
      result: ''
    }
  },
  methods: {
    exec() {
      // 取得
      const jsonUrl = this.url
      if(this.method == 'GET') {
        axios.get(jsonUrl)
          .then(res => {
            this.result = JSON.stringify(res.data)
          })
          .catch(err => {
            console.log(err)
            this.result = err
          })
      }else if(this.method == 'POST') {
        const params = JSON.parse(this.data)
        axios.post(jsonUrl,params)
          .then(res => {
            this.result = JSON.stringify(res.data)
          })
          .catch(err => {
            console.log(err)
            this.result = err
          })
      }else if(this.method == 'PATCH') {
        const params = JSON.parse(this.data)
        axios.patch(jsonUrl,params)
          .then(res => {
            this.result = JSON.stringify(res.data)
          })
          .catch(err => {
            console.log(err)
            this.result = err
          })
      }else if(this.method == 'DELETE') {
        axios.delete(jsonUrl)
          .then(res => {
            this.result = JSON.stringify(res.data)
          })
          .catch(err => {
            console.log(err)
            this.result = err
          })
      }
    }
  }
}
</script>